.audio-player {
  width: 100%;
}

.audio-player::-webkit-media-controls-timeline,
/* .audio-player::-webkit-media-controls-current-time-display, */
.audio-player::-webkit-media-controls-time-remaining-display,
.audio-player::-webkit-media-controls-more-button
{
  display: none;
}

.audio-player::-webkit-media-controls-panel {
  padding: 0 20px;
  justify-content: space-between; /* control panel elements are flex positioned */
}
