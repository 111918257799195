.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
}

.spinner-container-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* fullscreen background */
}

.spinner {
	border-top: 3px solid #4DB3C1;
  border-left: 3px solid #4DB3C1;
  border-right: 3px solid transparent;
  border-bottom: 0;

	animation: rotate 1.5s linear infinite;

  height: 54px;
	width:  54px;

	border-radius: 50%;

  &:before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 11.6C20 10.96 19.44 10.4 18.8 10.4H14.4L10.4 4H8.8L10.8 10.4H6.4L5.2 8.8H4L4.8 11.6L4 14.4H5.2L6.4 12.8H10.8L8.8 19.2H10.4L14.4 12.8H18.8C19.44 12.8 20 12.24 20 11.6Z' fill='black'/%3E%3C/svg%3E%0A");
    display: block;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 999;
    top: -19px;
    left: 19px;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: rotate(41deg);
  }
}
@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}
