footer {
  color: white;
}

footer a {
  color: white;
}

footer .social-media-icon {
  width: 22px;
  height: 22px;
}